import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { Route, Router } from 'preact-router';

import Header from './header';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Gen from '../routes/gen';

const App = () => (
	<div id="app">
		<EmoBg />
		<Header />
		<main>
			<Router>
				<Route path="/" component={Home} />
				<Route path="/gen/" component={Gen} />
				{/* <Route path="/emo/" component={() => (<div id="emoji" style="font-size:144pt; line-height:144pt; background:white;padding:0; position: absolute; left:0;top:0;display:flex;align-items:center;height:192px;width:192px;">🥰</div>)} /> */}
			</Router>
		</main>
	</div>
);

const EmoBg = () => {
	const tilearray = []
	const size = 100;
	const density = 0.5;
	const W = typeof window !== 'undefined' ? window : {innerHeight: 1000, innerWidth: 1500};
	let H = 0;
	if (typeof document !== 'undefined' && document.getElementById("app")) {
		H = document.getElementById("app").clientHeight + 200;
	}
	H = Math.max(H, W.innerHeight)
	for(let i = 0; i < H / size; i++) {
		for(let j = 0; j < W.innerWidth / size; j++) {
			if((i + j) % Math.floor(1/density) === 0) {
				tilearray.push(EmoTile({x: j*100, y: i*100}))
			}
		}
	}
	return (
		<div style="position: fixed; overflow:hidden; left:0;right:0;top:0;bottom:0;z-index:-1;">
			{tilearray}
		</div>
	);
};

const emoarr = ["🤪","🔥","😎","🎉","👾","🦄","🤘","🌈","👻","🎨","💥","🍄","🌀","🍕","🧨","💣","🎢","👽","💄","💀","🤯","👹","🥳","🧿"];

const EmoTile = (props: {x:Number, y:Number}) => {
	const [em, setEm] = useState<string>(emoarr[Math.floor(Math.random()*emoarr.length)]);

	useEffect(() => {
		let timer = setInterval(() => setEm(emoarr[Math.floor(Math.random()*emoarr.length)]), 20000*Math.random()+5000);
		// We want a minimum for the above so things don't swap too fast. But we also want immediate response.
		setTimeout(() => setEm(emoarr[Math.floor(Math.random()*emoarr.length)]), 20000*Math.random())
		return () => clearInterval(timer);
	}, []);

	return (
		<div style={`position: absolute; opacity: 0.3; left:${props.x}px; top: ${props.y}px; font-size:50px; z-index:1;`}>
			{em}
		</div>
	);
};

export default App;
